(function(window) {
    window.addEventListener("load", function () {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#252e39"
                },
                "button": {
                    "background": "#14a7d0"
                }
            },
            "showLink": false,
            "content": {
                "message": "Este website utiliza cookies para fornecer uma melhor experiencia",
                "dismiss": "Aceitar Cookies"
            }
        })
    });

    if ('serviceWorker' in navigator && process.env.NODE_ENV == "production") {
        navigator.serviceWorker.register('/sw.js')
            .then(function (reg) {
                // registration worked
                console.log('Registado com successo');
            }).catch(function (error) {
                // registration failed
                console.log('Não foi possivel registar');
            });
    }

    var loadDeferredStyles = function () {
        var addStylesNode = document.getElementById("deferred-styles");
        var replacement = document.createElement("div");
        replacement.innerHTML = addStylesNode.textContent;
        document.body.appendChild(replacement)
        addStylesNode.parentElement.removeChild(addStylesNode);
    };
    var raf = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    if (raf) raf(function () {
        window.setTimeout(loadDeferredStyles, 0);
    });
    else window.addEventListener('load', loadDeferredStyles);
})(window)
